<template>
  <AtomsGlobalLink :to="props.url" class="flex flex-col gap-4 justify-between p-8 flex-1 aspect-square text-right bg-silver bg-cover" :style="`background-image: url(${props.imageUrl})`">
    <h3 class="ml-auto max-w-[200px] text-2xl font-secondary text-white">{{ props.title }}</h3>
    <span>
      <MoleculesButtonsButton :dummy="true" :arrowRight="true" @click="useRouter().push(props.url)">{{ $t('Přečíst') }}</MoleculesButtonsButton>
    </span>
  </AtomsGlobalLink>
</template>

<script setup>

const props = defineProps({
  title: String,
  url: String,
  imageUrl: String,
  date: String,
  minutesToRead: Number
});


const getDate = () => useUtils().formatDate(new Date(props.date));

</script>